import React from "react";
import esmagicologo from "../../assets/esmagico.png";
import mailpng from "../../assets/mail.png";
import bgImg from "../../assets/FooterBG.png";
import instagram from "../../assets/svg/instagram.svg";
import linkedin from "../../assets/svg/linkedin.svg";
import heartEye from "../../assets/Heart-eyes.gif";
const Footer = () => {
  return (
    <footer className="mt-20 " style={{ backgroundImage: `url(${bgImg})` }}>
      <div className=" w-full flex justify-evenly  flex-col md:flex-row">
        <div className=" flex flex-col justify-center items-center md:items-start md:mx-5  h-full">
          <img src={esmagicologo} alt="" />
          {/* <p className="md:w-64 text-black text-base font-outfit font-normal leading-7 me-3">
            Your trusted partner for AI and digital solutions.
          </p> */}
          <p class="font-outfit text-[14px] font-normal leading-[17.64px] text-left text-[rgba(42,51,66,0.7)]">
            Visit us at
          </p>
          <p class="font-outfit w-[230px] lg:w-[264px] md:text-[16px] text-[14px] font-normal leading-[20.16px] leading-[17.64px] text-center md:text-left text-[#0F0F0F]">
            1307, Lodha Supremus, Saki Vihar Road, Powai, Mumbai
          </p>
          <p class="font-outfit text-[14px] mt-[32px] font-normal leading-[17.64px] text-left text-[rgba(42,51,66,0.7)]">
            Email us at
          </p>
          <a href="mailto:i@esmagico.in" class="font-outfit lg:w-[264px] text-[16px] font-normal leading-[20.16px] text-left text-[#0F0F0F]">
            i@esmagico.in
          </a>

          {/* <div>
            <div className="text-lg font-semibold mb-1 font-montserrat break-words mt-[30px]">
              Find us on{" "}
            </div>
            <div className="flex gap-3 justify-start items-center">
              <a
                className="cursor-pointer"
                href="https://www.linkedin.com/company/esmagico/mycompany/"
                rel="noreferrer"
                target="_blank"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
              <a
                className="cursor-pointer"
                href="https://www.instagram.com/esmagico.in?igsh=YTgxdWk1dGE0OWgx"
                rel="noreferrer"
                target="_blank"
              >
                <img src={instagram} alt="instagram" />
              </a>
            </div>
          </div> */}
        </div>

        <div className="flex gap-12px justify-center items-center lg:gap-0 mt-8 lg:mt-0">
          <div className="flex flex-col  md:items-start mx-3 lg:mx-14 gap-4 h-full">
            <h3 className="text-lg font-semibold mb-1 font-montserrat break-words">
              Product
            </h3>

            <a
              href="/services"
              className="text-gray-700 text-base font-source-sans font-normal leading-6 break-words"
            >
              Services
            </a>
            <a
              href="/products"
              className="text-gray-700 text-base font-source-sans font-normal leading-6 break-words"
            >
              Products
            </a>
            <a
              href="/Work"
              className="text-gray-700 text-base font-source-sans font-normal leading-6 break-words"
            >
              Work
            </a>
            <a
              href="/Teams"
              className="text-gray-700 text-base font-source-sans font-normal leading-6 break-words"
            >
              Teams
            </a>

          </div>
          {/* <div className="flex flex-col items-start md:ml-[0px] ml-[20vw] gap-4 h-full">
            <h3 className="text-lg font-semibold mb-1 font-montserrat break-words">
              More
            </h3>
            <a
              href="#"
              className="text-gray-700 text-base font-source-sans font-normal leading-6 break-words"
            >
              About Us
            </a>
            <a
              href="#"
              className="text-gray-700 text-base font-source-sans font-normal leading-6 break-words"
            >
              Blog
            </a>
            <a
              href="#"
              className="text-gray-700 text-base font-source-sans font-normal leading-6 break-words"
            >
              Contact
            </a>
          </div> */}

        </div>
        {/* <div >
          <div className="text-lg items-center font-semibold mb-1 font-montserrat break-words ">
            Find us on{" "}
          </div>
          <div className="flex gap-3 md:justify-start items-center">
            <a
              className="cursor-pointer"
              href="https://www.linkedin.com/company/esmagico/mycompany/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.instagram.com/esmagico.in?igsh=YTgxdWk1dGE0OWgx"
              rel="noreferrer"
              target="_blank"
            >
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div> */}
        <div >
          <div className="text-lg mt-[20px] md:mt-[0px] font-semibold mb-1 font-montserrat break-words text-center md:text-left">
            Find us on
          </div>
          <div className="flex  md:flex-row gap-3 justify-center md:justify-start items-center">
            <a
              className="cursor-pointer"
              href="https://www.linkedin.com/company/esmagico/mycompany/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.instagram.com/esmagico.in?igsh=YTgxdWk1dGE0OWgx"
              rel="noreferrer"
              target="_blank"
            >
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center sm:items-start h-full mx-5 mt-12 lg:mt-0">
          <div className=" h-[30px] w-[30px] text-left ">
            <img src={heartEye} alt="gif" />
          </div>
          <div className="w-full text-[#0F0F0F] text-lg lg:text-2xl  flex justify-center item-center sm:justify-start font-semibold font-montserrat break-words">
            Ready to delight your users?
          </div>
          {/* <button className=" p-2 px-10 bg-gray-900 rounded-3xl flex justify-center items-center hover:bg-gray-800 transition duration-300 ease-in-out gap-4 mt-6">
            <span className="text-white text-lg font-Outfit font-semibold break-words">
              Build
            </span>
            <img src={mailpng} alt="" />
          </button> */}

          <a
            href="http://wa.me/919468644446"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 px-10 bg-gray-900 rounded-3xl flex justify-center items-center hover:bg-gray-800 transition duration-300 ease-in-out gap-4 mt-6"
          >
            <span className="text-white text-[16px] font-Outfit font-semibold break-words ">
              Build
            </span>
            <img src={mailpng} alt="" />
          </a>
        </div>
      </div>

      <div className="mt-10 mb-5">
        <div className=" border-b-2"></div>
        <p className="text-gray-700 text-sm font-outfit font-normal leading-5 break-words text-center mt-5">
          © 2024 Es Magico. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
