import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

// Import images
import Image1 from "../../assets/img/Samagraport.png"; // Replace with actual path
import Image2 from "../../assets/img/Plumprod.png"; // Replace with actual path
import Image3 from "../../assets/img/kekaport.png"; // Replace with actual path
import Image4 from "../../assets/img/ISBport.png"; // Replace with actual path
import Image5 from "../../assets/img/TataNeuport.png"; // Replace with actual path

// Array of text contents for each box
const textContents = [

    {
        top: "Plum",
        bottom:
            "Consumer App Driving Commerce through Community and Content Plum has fast become one of India’s largest and fastest growing D2C Skincare brands. The brand features a fresh line of 100% vegan beauty products that are all about ‘being good’. - Raised Series C at $200m Valuation",
    },

    {
        top: "Keka",
        bottom:
            "Keka is a leading smart HR software that helps create automated processes for easier management and helps organizations build a engaging and driven culture for better decision making. - Raised $57mn in Series A  funding  - Valued at nearly $118mn",
    },
    {
        top: "ISB",
        bottom:
            "An LLM based interview bot that scrapes the user profile and uses GPT 4 to conduct a personalized interview. The candidate profile is fed to the fine-tuned GPT model which conducts an interview and scores the candidate on the basis of a set of pre -defined personality traits",
    },
    {
        top: "Tata Neu",
        bottom:
            "Powering Tata Neu’s Loyalty and Gamification",
    },
    {
        top: "Samagra",
        bottom:
            "LLM based multilingual chatbots trained on custom datasets that help the end user obtain answers to their queries in a simple conversational manner",
    },
];

// Array of custom border styles for each box
// const borderStyles = [
//     "border-[#DCF0FA]",
//     "border-[#59595975]",
//     "border-[#9FA73524]",
//     "border-[#538CFF38]",
//     "border-[#CCDCFF]",
// ];

// Slider settings
const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2, // Show 2.05 boxes at a time
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    centerPadding: "15px",
    responsive: [

        {
            breakpoint: 770,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "15px",
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1, // Show only 1 box on mobile
                slidesToScroll: 1,
                centerMode: false, // Disable center mode on mobile
                centerPadding: "0", // Remove extra padding on mobile
            },
        },

    ],
};

// BoxSlider Component
const BoxSlider = () => {
    return (
        <div className="relative overflow-hidden ">
            <Slider {...settings}>
                {[Image2, Image3, Image4, Image5, Image1].map((image, index) => (
                    <div className="p-[20px] md:w-[90%]">
                        <div
                            key={index}
                            className="flex flex-col   rounded-lg  md:p-4  "
                        >
                            {/* Image in the first div */}
                            <div className="flex-grow rounded-lg mb-2 overflow-hidden">
                                <img
                                    src={image}
                                    alt={`Box ${index + 1}`}
                                    className=" w-full object-cover"
                                />
                            </div>

                            {/* Text in the second vertical div */}
                            <div className="flex flex-col px-2 justify-center  items-center md:items-start h-[20%] md:h-[131px]">
                                <div className=" rounded-lg font-bold leading-[25.2px] text-black ">{textContents[index].top}</div>
                                <div className=" rounded-lg text-[14px]" style={{ color: '#7A7A7A' }}>{textContents[index].bottom}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

// BreadcrumbWork Component
const Portfolio = () => {
    return (
        <div>


            {/* Second section with scrollable boxes */}
            <div className="flex mt-[50px] flex-col p-4 bg-[#F9F9F9]">
                <div className="mb-4">
                    <p className="text-[25px] leading-[25px] text-center font-semibold md:text-[38px] md:leading-[48px]">
                        Our Product portfolio
                    </p>
                </div>

                <div className="mt-[50px] overflow-hidden">
                    <BoxSlider />
                </div>

                {/* View All Button */}
                <div className="flex justify-center mt-10">
                    <Link to="/Work/Portfoliodetailpage">
                        <button className="bg-black text-white py-2 px-14 rounded-full">
                            View All
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
