import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

// Import images
import Image1 from "../../assets/img/ISB Work.png"; // Replace with actual path
import Image2 from "../../assets/img/RBIWork.png"; // Replace with actual path
import Image3 from "../../assets/img/TataneuWork.png"; // Replace with actual path
import Image4 from "../../assets/img/KrushAiWork.png"; // Replace with actual path
import Image5 from "../../assets/img/AdvancedWork.png"; // Replace with actual path

// Array of text contents for each box
const textContents = [
    {
        top: "RBI",
        bottom:
            "The chatbot takes the basic user information and maps it to their respective bank details. Following this, details about any excess charges are extracted and any related queries that they may have are solved",
    },
    {
        top: "Tata Neu",
        bottom:
            "Our rewards platform leverages AI to dynamically generate content, notifications, and personalized offers tailored to user behavior and predefined rules, enhancing engagement and satisfaction",
    },
    {
        top: "KrushAI - BOT FOR FARMERS",
        bottom:
            "KrushAI Bot is an advanced AI model trained on agricultural data, specifically focusing on Indian crop cycles. It is multilingual and currently operates in Hindi and Odiya. Farmers can utilize this bot to obtain answers to their queries in a simple conversational manner",
    },
    {
        top: "ADVANCED ANALYTICS AI",
        bottom:
            "Advanced Analytics employs AI to conduct sentiment analysis on sales meetings, evaluating sales call quality across various products and suggesting potential corrective actions",
    },
    {
        top: "ISB",
        bottom:
            "The candidate profile is fed to the fine-tuned GPT model which goes on to conduct a personalized interview. The candidate is scored on the basis of a set of predefined personality traits",
    },
];

// Array of custom border styles for each box
const borderStyles = [
    "border-[#DCF0FA]",
    "border-[#59595975]",
    "border-[#9FA73524]",
    "border-[#538CFF38]",
    "border-[#CCDCFF]",
];

// Slider settings
const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2, // Show 2.05 boxes at a time
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    centerPadding: "15px",
    responsive: [

        {
            breakpoint: 770,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "15px",
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1, // Show only 1 box on mobile
                slidesToScroll: 1,
                centerMode: false, // Disable center mode on mobile
                centerPadding: "0", // Remove extra padding on mobile
            },
        },

    ],
};

// BoxSlider Component
const BoxSlider = () => {
    return (
        <div className="relative overflow-hidden ">
            <Slider {...settings}>
                {[Image2, Image3, Image4, Image5, Image1].map((image, index) => (
                    <div className="p-[10px]">
                        <div
                            key={index}
                            className="flex flex-col rounded-lg md:p-4"
                        >
                            {/* Image in the first div */}
                            <div className="flex-grow rounded-lg mb-2 overflow-hidden">
                                <img
                                    src={image}
                                    alt={`Box ${index + 1}`}
                                    className="w-fill object-cover"
                                />
                            </div>

                            {/* Text in the second vertical div */}
                            <div className="flex flex-col px-2 justify-center  items-center md:items-start h-[20%] md:h-[131px]">
                                <div className=" rounded-lg font-bold leading-[25.2px] text-black ">{textContents[index].top}</div>
                                <div className=" rounded-lg text-[14px]" style={{ color: '#7A7A7A' }}>{textContents[index].bottom}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

// BreadcrumbWork Component
const BreadcrumbWork = () => {
    return (
        <div>
            {/* First section with text */}
            <div className="mt-[100px] bg-[#F7F7F7] h-[200px] flex justify-center items-center w-full p-4">
                <p className="text-black text-center font-outfit text-[28px] px-[50px] md:text-[70px] leading-[35px] md:leading-[80px]">
                    Products that define <span className="font-bold">our work</span>
                </p>
            </div>

            {/* Second section with scrollable boxes */}
            <div className="flex mt-[50px] flex-col p-4">
                <div className="mb-4">
                    <p className="text-[25px] leading-[25px] text-center font-semibold md:text-[38px] md:leading-[48px]">
                        Products Built using&nbsp;
                        <span className="bg-gradient-to-r from-[#FEA26E] to-[#538CFF] text-transparent bg-clip-text">
                            AI
                        </span>
                    </p>
                </div>

                <div className="mt-[50px] overflow-hidden">
                    <BoxSlider />
                </div>

                {/* View All Button */}
                <div className="flex justify-center mt-10">
                    <Link to="/Work/Detailpage">
                        <button className="bg-black text-white py-2 px-14 rounded-full">
                            View All
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default BreadcrumbWork;
